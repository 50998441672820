import React, { useState, useEffect, useRef, useContext } from 'react';
import Link from 'src/front/components/Link';
import { IS_SERVER, API_URI, MONTHS, DIMENSION_UNITS, LANDING_LAST_SEARCH_LOCATIONS_COUNT } from 'src/constants';
import useLang from 'src/hooks/useLang';
import LoadingScreen from 'src/components/LoadingScreen';
import ComponentStyle from 'src/components/ComponentStyle';
import MapBox from 'src/front/components/MapBox';
import axios from 'axios';
import Helper from 'src/front/helpers/Helper';
import mapboxgl from 'mapbox-gl';
import DimensionUnitsConvertor from 'src/utils/DimensionUnitsConvertor';
import useHandlePopup, { FrontContext } from 'src/front/helpers/FrontContext';
import Thermometer from 'src/front/components/Thermometer';
import { Edit, Cross, InteractiveMap } from 'src/front/components/icons';
import $ from 'jquery';
import ScrollHidden from 'src/front/components/ScrollHidden';
import Footer from 'src/front/components/Footer';
import ReactDOMServer from 'react-dom/server';
import { StaticRouter } from 'react-router-dom';
import { useInfoBlockData } from 'src/front/components/InfoBlocks';
import SunPosition from 'src/front/components/SunPosition';
import weatherService from 'src/services/weatherService';
import SearchLocation from 'src/front/components/SearchLocation';
import { useHistory } from 'react-router';
import CitiesList from 'src/front/components/CitiesList';

const mapActions = ['scrollZoom', 'dragRotate', 'dragPan', 'touchZoomRotate'];

function HomeLanding(props) {
  const [data, setData] = useState(
    props.staticContext && props.staticContext.page
    ? props.staticContext.page
    : (typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.page ? window.__REACT_INIT_DATA__.page : null)
  );
  if (typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.page) delete window.__REACT_INIT_DATA__.page;
  const { dimensionUnits, staticData, setProperty, myCities } = useContext(FrontContext);
  const history = useHistory();
  const infoBlocksData = useInfoBlockData();
  const [bigMap, setBigMap] = useState(null);
  const [smallMap, setSmallMap] = useState(null);
  const [mapState, setMapState] = useState('loading');
  const mapStateRef = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [isUserCitySelectOpened, setIsUserCitySelectOpened] = useState(data?.landingPageData?.detectedCity?.id !== myCities[0]?.id);
  const isUserCitySelectOpenedRef = useRef(null);
  const [interactiveMapModeHidden, setInteractiveMapModeHidden] = useState(false);
  const interactiveMapModeHiddenRef = useRef(null);
  const interactiveMapMode = useRef(null);
  const lang = useLang('Home');
  const markers = useRef(null);
  const mapPopup = useRef(null);
  const dimensionUnitsRef = useRef(null);
  const dataRef = useRef(null);
  const handleOpenPopup = useHandlePopup();
  const mapsRef = useRef({});
  const rotateCircleRef = useRef(null);
  const landingCity = useRef(null);
  const userCitySelect = useRef(null);

  dimensionUnitsRef.current = dimensionUnits;
  dataRef.current = data;
  mapsRef.current.big = bigMap;
  mapsRef.current.small = smallMap;
  mapStateRef.current = mapState;
  isUserCitySelectOpenedRef.current = isUserCitySelectOpened;
  interactiveMapModeHiddenRef.current = interactiveMapModeHidden;

  const handleSetBigMap = (map) => {
    setBigMap(map);

    map.on('idle', () => {
      const locations = map.queryRenderedFeatures({ layers: ['settlement-major-label', 'settlement-minor-label'] }).map((f) => ({
        name: f.properties.name_en,
        countryCode: f.properties.iso_3166_1,
        regionCode: f.properties.iso_3166_2,
        coordinates: f.geometry.coordinates,
        textAnchor: f.layer.layout['text-anchor']
      }));
    
      axios
        .post(`${API_URI}/get-locations-by-names`, {
          locations,
        }, { withCredentials: true })
        .then(({ data }) => {
          try {
            if (markers.current) markers.current.map((m) => m.remove());
    
            markers.current = [];
    
            data.locations.forEach((m) => {
              const element = document.createElement('div');
              element.className = 'mapbox-city-marker';
              element.innerHTML = `<img alt="Weather" class="city-weather" width="284" height="284" src="${Helper.getFileUrl('weather-icon', m.weather.icon + '.svg')}"><div class="city-tempr dimension-units-temp" data-value="${m.weather.temp}">${DimensionUnitsConvertor.temperature(m.weather.temp, dimensionUnitsRef.current.temperature)}</div>`;
    
              markers.current.push(new mapboxgl.Marker({ element, ...m.textAnchor && { offset: [0, m.textAnchor === 'bottom' ? 10 : 25] } })
                .setLngLat([m.center[0], m.center[1]]));
            });
    
            markers.current.forEach((m) => m.addTo(map));
          } catch (ex) {}
        });

      if (mapStateRef.current === 'active') {
        const lngLat = map.getCenter();

        axios
          .get(`${API_URI}/front-user/get-landing-page-data/${lang.currentLanguage._id}?localization=${encodeURIComponent(lang.currentLocalization.shortName)}&localizations=${lang.currentLanguage.localizations.length}&lngLat=${lngLat.lng},${lngLat.lat}`, { withCredentials: true })
          .then(({ data }) => {
            dataRef.current = { ...dataRef.current, landingPageData: { ...dataRef.current?.landingPageData, city: data.city } };
            setData(dataRef.current);
          });
      }
    });

    map.on('load', () => setMapLoaded(true));
  };

  const handleSetSmallMap = (map) => {
    const canvas = $(map.getCanvas());
    let popupMarker = null;

    setSmallMap(map);

    map.on('click', function(e) {
      if (mapStateRef.current !== 'active') {
        setMapState('active');
        return;
      }

      if (mapPopup.current) {
        mapPopup.current.remove();
        mapPopup.current = null;
      } else {
        mapPopup.current = new mapboxgl.Popup({ closeOnClick: false })
          .setLngLat(e.lngLat)
          .setHTML(ReactDOMServer.renderToString(<LoadingScreen/>))
          .addTo(map)
          .on('close', function () {
            if (this === mapPopup.current) mapPopup.current = null;
          });
  
        const coordinates = map.project(e.lngLat);
        const popupCoordinates = mapsRef.current.big.unproject([coordinates.x, coordinates.y + canvas.offset().top]);
    
        if (popupMarker) {
          popupMarker.setLngLat([popupCoordinates.lng, popupCoordinates.lat]);
        } else {
          const markerImg = document.createElement('img');
          markerImg.src = '/icons/red_marker.svg';
          markerImg.id = 'mapbox-popup-place-marker';
  
          popupMarker = new mapboxgl.Marker({ element: markerImg, anchor: 'bottom', offset: [0, 12] })
            .setLngLat([popupCoordinates.lng, popupCoordinates.lat])
            .addTo(mapsRef.current.big);
        }
    
        axios
          .get(`${API_URI}/geocode/${popupCoordinates.lng},${popupCoordinates.lat}/${lang.currentLanguage._id}`, { withCredentials: true })
          .then(({ data }) => mapPopup.current && mapPopup.current.setHTML(ReactDOMServer.renderToString(
              <StaticRouter>
                <div className="map-popup">
                  <div className="map-popup-top">
                    <div>
                      <Link to={lang.l(`/${data.country.link}/`)}>{data.country.name}</Link>
                    </div>
                    {data.region && <><Link to={lang.l(`/${data.country.link}/${data.region.link}`)}>{data.region.name}</Link>, </>}
                    <Link to={lang.l(`/${data.country.link}/${data.city.link}`)}>{data.city.name}</Link>
                  </div>
                  <div className="map-popup-weather">
                    <div className="map-popup-icon-temp fs-0">
                      <img className="inline-block va-middle" alt="Weather" src={Helper.getFileUrl('weather-icon', `${data.weather.icon}.svg`)}/>
                      <div className="inline-block va-middle map-popup-temp dimension-units-temp" data-value={data.weather.temp}>{DimensionUnitsConvertor.temperature(data.weather.temp, dimensionUnitsRef.current.temperature)}<span>{DIMENSION_UNITS.find('temperature', dimensionUnitsRef.current.temperature).label.substring(1)}</span></div>
                    </div>
                    <div className="map-popup-temp-feel"><span>{lang.t('Feels like', null, true)}</span> <span className="dimension-units-temp" data-value={data.weather.tempFeels}>{DimensionUnitsConvertor.temperature(data.weather.tempFeels, dimensionUnitsRef.current.temperature)}</span></div>
                    <div className="map-popup-cloudiness">{lang.t(data.weather.cloudiness)}</div>
                    <table>
                      <tbody>
                        <tr>
                          <th>{lang.t('Wind', null, true)}</th>
                          <td><span className="map-popup-wind-speed dimension-units-wind-speed" data-value={data.weather.windSpeed}>{DimensionUnitsConvertor.windSpeed(data.weather.windSpeed, dimensionUnitsRef.current.windSpeed)} {lang.t(DIMENSION_UNITS.find('windSpeed', dimensionUnitsRef.current.windSpeed).label, null, true)}</span> <img alt="Wind" className={`va-middle wind-direction ${data.weather.windDirection}`} src="/icons/wind.svg"/></td>
                        </tr>
                        <tr>
                          <th>{lang.t('Pressure', null, true)}</th>
                          <td>{data.weather.pressure} {lang.t('mmHg.')}</td>
                        </tr>
                        <tr>
                          <th>{lang.t('Humidity', null, true)}</th>
                          <td>{data.weather.wet}%</td>
                        </tr>
                        <tr>
                          <th>{lang.t('Water temperature', null, true)}</th>
                          <td className="dimension-units-temp" data-value={data.weather.bathingTemp}>{DimensionUnitsConvertor.temperature(data.weather.bathingTemp, dimensionUnitsRef.current.temperature)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <a className="map-popup-details" href={lang.l(`/${data.country.link}/${data.city.link}`)}>{lang.t('Detailed forecast')}</a>
                </div>
              </StaticRouter>
            ))
          );
      }
    });

    map.on('move', () => {
      if (mapPopup.current) mapPopup.current.remove();

      if (mapsRef.current.big) {
        const center = map.project(map.getCenter());

        mapsRef.current.big.jumpTo({
          center: map.unproject([center.x, center.y - canvas.offset().top + (window.innerHeight - canvas.height()) / 2]),
          zoom: map.getZoom(),
        });
      }
    });

    map.on('rotate', () => {
      if (mapsRef.current.big) {
        mapsRef.current.big.setBearing(map.getBearing());
        if (mapPopup.current) mapPopup.current.remove();
      }
    });
  
    map.on('pitch', () => {
      if (mapsRef.current.big) {
        mapsRef.current.big.setPitch(map.getPitch());
        if (mapPopup.current) mapPopup.current.remove();
      }
    });

    let prevZoom = 0;

    map.on('zoom', (ev) => {
      const zoom = map.getZoom();
      const zoomStep = 5;
      let rotate = rotateCircleRef.current.getAttribute('style')?.match(/rotate\((\d+)deg\)/);
      rotate = rotate ? +rotate[1] : 0;
      let nextRotate = prevZoom < zoom ? rotate - zoomStep : rotate + zoomStep;
      if (nextRotate > 360) nextRotate -= 360;
      if (nextRotate < 0) nextRotate += 360;

      rotateCircleRef.current.style.transform = `rotate(${nextRotate}deg)`;

      prevZoom = zoom;
    });
  };

  const handleDisableMap = () => setMapState('not-active');

  const confirmUserCityHandle = (ev) => {
    ev.preventDefault();

    for (let i = 0; i < myCities.length; i++) {
      if (i && myCities[i].id === data.landingPageData.detectedCity.id) {
        for (let j = i; j--;) myCities[j + 1] = myCities[j];
      }
    }

    myCities[0] = data.landingPageData.detectedCity;

    setIsUserCitySelectOpened(false);
    setProperty('myCities', [...myCities]);
    Helper.setCookie('my_cities', myCities.map((c) => c.id).join(','), { expires: new Date(Date.now() + 60 * 60 * 24 * 365 * 100000) });
  };

  const changeUserCityHandle = (event) => {
    handleOpenPopup({
      event,
      element: $('#my-cities-popup > div'),
      name: 'my-cities-popup',
      closeCheck: (ev) => ev.target.id === 'my-cities-popup',
    });
  };

  const handleRememberLocation = (locations) => {
    const usedCities = [];
    const lastSearchLocations = locations.slice(0, LANDING_LAST_SEARCH_LOCATIONS_COUNT).map((l) => {
      if (l.type === 'city') usedCities.push(l._id);
      return { mapBoxPosition: l.mapBoxPosition, center: l.center, name: Helper.getFieldValue(l.name, lang.currentLanguage._id), link: l.link };
    });

    for (let i = 0; i < myCities.length && lastSearchLocations.length < LANDING_LAST_SEARCH_LOCATIONS_COUNT; i++) {
      if (!usedCities.includes(myCities[i].id)) {
        lastSearchLocations.push({ mapBoxPosition: myCities[i].mapBoxPosition, center: myCities[i].center, name: myCities[i].name, link: myCities[i].link });
      }
    }

    if (!Helper.compare(lastSearchLocations, dataRef.current?.landingPageData?.lastSearchLocations)) {
      dataRef.current = { ...dataRef.current, landingPageData: { ...dataRef.current?.landingPageData, lastSearchLocations } };
      setData(dataRef.current);
    }
  };

  const handleClickLastSearchLocation = (ev, location) => {
    ev.preventDefault();

    setMapState('active');

    moveMapToLocationPosition(location);
  };

  const moveMapToLocationPosition = (l) => {
    if (l.mapBoxPosition && /^\/\w+\/(-?[\de-]+(?:\.[\de-]+)?,){4}-?[\de-]+(?:\.[\de-]+)?$/.test(l.mapBoxPosition)) {
      const [lng, lat, zoom, bearing, pitch] = l.mapBoxPosition.substring(l.mapBoxPosition.indexOf('/', 1) + 1).split(',').map((v) => +v);

      smallMap.flyTo({
        center: [lng, lat],
        zoom,
        bearing,
        pitch,
        duration: 3000,
      });
    } else if (l.center && l.center.length === 2) {
      smallMap.flyTo({
        center: l.center,
        zoom: 10,
        bearing: 0,
        pitch: 0,
        duration: 3000,
      });
    }
  };

  useEffect(() => {
    if (smallMap) {
      if (mapState === 'active') {
        mapActions.forEach((action) => smallMap[action].enable());
        landingCity.current = data?.landingPageData?.city;
      } else {
        mapActions.forEach((action) => smallMap[action].disable());

        if (landingCity.current) {
          dataRef.current = { ...dataRef.current, landingPageData: { ...dataRef.current?.landingPageData, city: landingCity.current } };
          setData(dataRef.current);
          landingCity.current = null;
        }
      }
    }
  }, [mapState, !!smallMap]);

  useEffect(() => {
    if (dimensionUnits?.temperature) {
      if (markers.current) {
        markers.current.forEach((m) => {
          for (let i = 0, elements = m.getElement().getElementsByClassName('dimension-units-temp'), element = elements[i]; i < elements.length; element = elements[++i]) {
            element.innerHTML = DimensionUnitsConvertor.temperature(element.getAttribute('data-value'), dimensionUnits.temperature);
          }
        });
      }

      if (mapPopup.current) {
        for (let i = 0, elements = mapPopup.current.getElement().getElementsByClassName('dimension-units-temp'), element = elements[i]; i < elements.length; element = elements[++i]) {
          element.innerHTML = DimensionUnitsConvertor.temperature(element.getAttribute('data-value'), dimensionUnits.temperature);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensionUnits?.temperature]);

  useEffect(() => {
    if (dimensionUnits?.windSpeed && mapPopup.current) {
      for (let i = 0, elements = mapPopup.current.getElement().getElementsByClassName('dimension-units-wind-speed'), element = elements[i]; i < elements.length; element = elements[++i]) {
        element.innerHTML = DimensionUnitsConvertor.windSpeed(element.getAttribute('data-value'), dimensionUnits.windSpeed, lang);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensionUnits?.windSpeed]);

  useEffect(() => {
    if (data?.landingPageData?.city?.center?.constructor === Array && data.landingPageData.city.center.length === 2 && smallMap && mapLoaded && mapState !== 'active') {
      if (mapState === 'loading') setMapState('loaded');
      smallMap.flyTo({
        center: data.landingPageData.city.center,
        zoom: 10,
        bearing: 0,
        pitch: 0,
        duration: 3000,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapLoaded, smallMap, data?.landingPageData?.city?.center]);

  useEffect(() => {
    document.body.className = 'home landing';

    if (!data?.page) {
      axios
        .get(`${API_URI}/home-page/${lang.currentLanguage._id}/${lang.currentLocalizationIndex}`, { withCredentials: true })
        // eslint-disable-next-line no-sequences
        .then(({ data }) => (dataRef.current = { ...dataRef.current, ...data }, setData(dataRef.current)));
    }

    const touchStart = (sev) => {
      if (userCitySelect.current && userCitySelect.current.contains(sev.touches[0].target)) {
        const touchMove = (mev) => {
          if (isUserCitySelectOpenedRef.current && sev.touches[0].clientX + 10 < mev.touches[0].clientX) setIsUserCitySelectOpened(false);
          else if (!isUserCitySelectOpenedRef.current && sev.touches[0].clientX - 10 > mev.touches[0].clientX) setIsUserCitySelectOpened(true);
        };
        const touchEnd = () => {
          window.removeEventListener('touchmove', touchMove);
          window.removeEventListener('touchend', touchEnd);
        };

        window.addEventListener('touchmove', touchMove);
        window.addEventListener('touchend', touchEnd);
      }

      if (interactiveMapMode.current && interactiveMapMode.current.contains(sev.touches[0].target)) {
        const touchMove = (mev) => {
          if (interactiveMapModeHiddenRef.current && sev.touches[0].clientX - 10 > mev.touches[0].clientX) setInteractiveMapModeHidden(false);
          else if (!interactiveMapModeHiddenRef.current && sev.touches[0].clientX + 10 < mev.touches[0].clientX) setInteractiveMapModeHidden(true);
        };
        const touchEnd = () => {
          window.removeEventListener('touchmove', touchMove);
          window.removeEventListener('touchend', touchEnd);
        };

        window.addEventListener('touchmove', touchMove);
        window.addEventListener('touchend', touchEnd);
      }
    };

    window.addEventListener('touchstart', touchStart);

    return () => window.removeEventListener('touchstart', touchStart);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!data?.landingPageData || data.landingPageData.city.id !== myCities[0]?.id) {
      let _myCities = Helper.getCookie('my_cities');

      axios
        .get(`${API_URI}/front-user/get-landing-page-data/${lang.currentLanguage._id}?localization=${encodeURIComponent(lang.currentLocalization.shortName)}&localizations=${lang.currentLanguage.localizations.length}${_myCities ? '&city=' + _myCities.split(',').shift() : ''}`, { withCredentials: true })
        .then(({ data }) => {
          setIsUserCitySelectOpened(data.detectedCity.id !== myCities[0]?.id);
          dataRef.current = { ...dataRef.current, landingPageData: { ...dataRef.current?.landingPageData, ...data } };
          setData(dataRef.current);
          staticData.setTopLandingPageCity && staticData.setTopLandingPageCity(data.city);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myCities[0]?.id]);

  useEffect(() => {
    if (data?.page && lang.loaded) {
      Helper.setSEO(data.page, lang, 'page');
      staticData.setPageDataTop(data.page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.page, lang.loaded]);

  useEffect(() => {
    if (data?.landingPageData?.detectedCity?.id === myCities[0]?.id && isUserCitySelectOpened) {
      const handleClick = (ev) => {
        if (!ev.target.closest('.user-city-select')) setIsUserCitySelectOpened(false);
      };

      window.addEventListener('click', handleClick);

      return () => window.removeEventListener('click', handleClick);
    }
  }, [data?.landingPageData?.detectedCity?.id === myCities[0]?.id && isUserCitySelectOpened]);

  if (!IS_SERVER && (!data?.landingPageData?.city || !data?.page || !lang.loaded || !infoBlocksData)) return <LoadingScreen/>

  return (
    <ComponentStyle styles={Helper.transformStyles(lang.currentLanguage, [{ src: '/css/home-landing.css' }, { src: '/css/home-landing-media.css' }], [{ src: 'https://api.mapbox.com/mapbox-gl-js/v1.12.0/mapbox-gl.css' }])} Preloader={<LoadingScreen/>}>
      <div className={`map-wrapper relative ${mapState}`}>
        <svg id="map-svg" width="1000" height="1000" viewBox="0 0 1000 1000">
          <g ref={rotateCircleRef}>
            <path d="M999 500 A 499 499 0 1 1 999 499.999" stroke-dasharray="2,7" stroke-dashoffset="5" stroke="#146ff9" fill="none" stroke-width="2" vector-effect="non-scaling-stroke"/>
          </g>
          <path d="M1021.5 500 A 521.5 521.5 0 1 1 1021.5 499.999" stroke-opacity="0.10" stroke="#146FF9" fill="none" stroke-width="43"/>
          <path d="M1128.5 500 A 628.5 628.5 0 1 1 1128.5 499.999" stroke-opacity="0.10" stroke="#146FF9" fill="none" stroke-width="257"/>
          <path d="M6000 500 A 5500 5500 0 1 1 6000 499.999" stroke-opacity="0.10" stroke="#146FF9" fill="none" stroke-width="10000"/>
        </svg>
        <MapBox onInit={handleSetBigMap} {...!mapLoaded && { style: { opacity: 0 } }}/>
        <div id="hidden-map">
          {/* eslint-disable-next-line no-sequences */}
          <MapBox params={mapActions.reduce((a, v) => (a[v] = false, a), {})} onInit={handleSetSmallMap}/>
        </div>
        <div className="project ta-center">
          <img
            alt={IS_SERVER ? '{print staticData.language.localizations[localization].project}' : lang.currentLocalization.project}
            src={Helper.getFileUrl('logotype', 'logo2.svg')}
          />
          <div className="text-11">
            {
              (IS_SERVER ? '' : lang.currentLocalization.project).split(' ').map((w, i) => (
                <>
                  {IS_SERVER && "{for (let w = staticData.language.localizations[localization].project.split(' '), i = 0; i < w.length; i++) :}"}
                  <span className={IS_SERVER ? "{print i ? 'color-2' : 'color-11'}" : (i ? 'color-2' : 'color-11')}>{IS_SERVER ? "{print w[i] + (!i ? ' ' : '')}" : `${w}${!i ? ' ' : ''}`}</span>
                  {IS_SERVER && '{endfor}'}
                </>
              ))
            }
          </div>
        </div>
        <div className="country-flag">
          <img
            alt={IS_SERVER ? '{print page.landingPageData.city.countryName}' : data.landingPageData.city.countryName}
            src={IS_SERVER ? "{print Helper.getFileUrl('country', page.landingPageData.city.countryFlag)}" : Helper.getFileUrl('country', data.landingPageData.city.countryFlag)}
          />
        </div>
        <Link className="country-link-orange text-9 color-7 inline-block" to={lang.l(IS_SERVER ? "{print page.landingPageData.city.countryLink}" : data.landingPageData.city.countryLink)}>{lang.t('Weather forecast in !country', { '!country': IS_SERVER ? '{print page.landingPageData.city.countryNameLocative}' : data.landingPageData.city.countryNameLocative })}</Link>
        <div className="search-location-wrapper relative">
          <SearchLocation
            types={'countries,regions,cities'}
            openTo="top"
            clearButton={false}
            usePortal={false}
            onRememberLocations={handleRememberLocation}
            onFocus={() => setMapState('active')}
            params={{ fields: { country: ['mapBoxPosition', 'center'], region: ['mapBoxPosition', 'center'], city: ['mapBoxPosition', 'center'] } }}
            autoLoadRememberedLocations={!data?.landingPageData?.lastSearchLocations}
            selectLocation={((l) => {
              if (mapState === 'active') moveMapToLocationPosition(l);
              else {
                history.push(lang.l(l.link));
                $('html, body').animate({ scrollTop: 0 }, 500);
              }
            })}
          />
          <img alt="Location" src={IS_SERVER ? "{print Helper.getFileUrl('images', 'user_location.svg')}" : Helper.getFileUrl('images', 'user_location.svg')}/>
          <ul className="my-cities ta-center">
            {(IS_SERVER ? [''] : data?.landingPageData?.lastSearchLocations)?.map((location) => (
              <>
                {IS_SERVER && '{for (let i = 0; i < page.landingPageData.lastSearchLocations.length; i++) :}'}
                <li className="inline-block va-middle">
                  <Link onClick={(ev) => handleClickLastSearchLocation(ev, location)} className="text-10 color-8 block" to={lang.l(IS_SERVER ? '{print page.landingPageData.lastSearchLocations[i].link}' : location.link)}>{IS_SERVER ? '{print page.landingPageData.lastSearchLocations[i].name}' : location.name}</Link>
                </li>
                {IS_SERVER && '{endfor}'}
              </>
            ))}
          </ul>
          <button className="absolute default-button fs-0" id="disable-map-active" onClick={handleDisableMap}>
            <Cross/>
          </button>
        </div>
        <div className="user-city-banner clear">
          <div className="user-city-current-wether">
            <img className="current-weather-icon" width="85" height="85" alt="Weather" src={IS_SERVER ? "{print Helper.getFileUrl('weather-icon', page.landingPageData.city.weather.icon + '.svg')}" : Helper.getFileUrl('weather-icon', `${data.landingPageData.city.weather.icon}.svg`)}/>
            <div className="country ta-center">
              <Link className="text-4 color-3" to={lang.l(IS_SERVER ? '{print page.landingPageData.city.countryLink}' : data.landingPageData.city.countryLink)}>{IS_SERVER ? '{print page.landingPageData.city.countryName}' : data.landingPageData.city.countryName}</Link>
            </div>
            <div className="city ta-center">
              <Link className="inline-block va-middle text-7 color-2" to={lang.l(IS_SERVER ? '{print page.landingPageData.city.cityLink}' : data.landingPageData.city.cityLink)}>{IS_SERVER ? '{print page.landingPageData.city.cityName}' : data.landingPageData.city.cityName}</Link>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className="inline-block va-middle" href="#" onClick={changeUserCityHandle} aria-label="My cities">
                <Edit className="block" color="#5C6684"/>
              </a>
            </div>
            <div className="date ta-center text-20 color-3">
              {
                IS_SERVER ?
                `
                  {literal}
                    <span>${lang.t('{print page.landingPageData.city.date.day}')}</span> {print page.landingPageData.city.date.date} <span>${lang.t({ string: '{print page.landingPageData.city.date.month}', variant: 1 })}</span> {print page.landingPageData.city.date.year}
                  {/literal}
                ` :
                <>
                  <span>{lang.t(data.landingPageData.city.date.day)}</span> {data.landingPageData.city.date.date} <span>{lang.t({ string: data.landingPageData.city.date.month, variant: 1 })}</span> {data.landingPageData.city.date.year}
                </>
              }
            </div>
            <div className="temp ta-center text-21" style={{ color: IS_SERVER ? '{print weatherService.getTempColor(page.landingPageData.city.weather.temp)}' : weatherService.getTempColor(data.landingPageData.city.weather.temp) }}>
              {IS_SERVER ? '{print DimensionUnitsConvertor.temperature(page.landingPageData.city.weather.temp, dimensionUnits.temperature)}' : DimensionUnitsConvertor.temperature(data.landingPageData.city.weather.temp, dimensionUnits.temperature)}
            </div>
            <div className="cloudiness ta-center text-22 color-14">{lang.t(IS_SERVER ? '{print page.landingPageData.city.weather.cloudiness}' : data.landingPageData.city.weather.cloudiness)}</div>
          </div>
          <div className="city-link ta-center">
            <Link className="block text-9 color-7" to={lang.l(IS_SERVER ? '{print page.landingPageData.city.cityLink}' : data.landingPageData.city.cityLink)}>{lang.t('More details')}</Link>
          </div>
          <div className="full-weather-forecast ta-center">
            <Link className="inline-block va-middle" to={lang.l(IS_SERVER ? '{print page.landingPageData.city.cityLink}' : data.landingPageData.city.cityLink)}>{lang.t('Full weather forecast')}</Link>
          </div>
        </div>
        <div className="hide-overflow-map absolute o-hidden">
          <div ref={interactiveMapMode} className={`interactive-map-mode absolute${interactiveMapModeHidden ? ' hidden' : ''}`}>
            <button onClick={() => setInteractiveMapModeHidden(!interactiveMapModeHidden)} className="toggle-hide default-button absolute"/>
            <InteractiveMap/>
            <span className="text-4 color-1">{lang.t('Interactive map mode')}</span>
            <button className="close default-button text-12 color-7" onClick={handleDisableMap}>{lang.t('Close')}</button>
          </div>
          <HoverPopup map={smallMap} lang={lang} dimensionUnitsRef={dimensionUnitsRef}/>
          <div ref={userCitySelect} className={`user-city-select inline-block${IS_SERVER ? "{print page.landingPageData.detectedCity.id !== myCities[0].id ? ' opened' : ''}" : (isUserCitySelectOpened ? ' opened' : '')}`}>
            <button onClick={() => setIsUserCitySelectOpened(!isUserCitySelectOpened)} className="toggle-user-city-select default-button"></button>
            <div className="inline-block va-middle user-city-select-text">
              <div className="user-city-question relative">
                <span className="text-4 color-1">
                  {
                    IS_SERVER ?
                    <>
                      {'{if (page.landingPageData.detectedCity.id == myCities[0].id) :}'}
                        {lang.t('Your city detected as !city', { '!city': '{print page.landingPageData.detectedCity.name}' })}
                      {'{else :}'}
                        {lang.t('Want to change location?')}
                      {'{endif}'}
                    </> :
                    (
                      data?.landingPageData?.detectedCity?.id === myCities[0]?.id ?
                      lang.t('Your city detected as !city', { '!city': data.landingPageData.detectedCity.name }) :
                      lang.t('Want to change location?')
                    )
                  }
                </span>
              </div>
              <div className="user-city-site-text text-10 color-1">
                {
                  IS_SERVER ?
                    <>
                    {'{if (page.landingPageData.detectedCity.id == myCities[0].id) :}'}
                      {lang.t('!domain in !country - these are the most complete and accurate data', { '!domain': '{print host}', '!country': '{print page.landingPageData.city.countryNameLocative}' })}
                    {'{else :}'}
                      {lang.t('Your location: <a class="color-2" href="!link">!city</a>', { '!city': '{print page.landingPageData.detectedCity.name}', '!link': lang.l('{print page.landingPageData.detectedCity.link}') })}
                    {'{endif}'}
                  </> :
                  (
                    data?.landingPageData?.detectedCity?.id === myCities[0]?.id ?
                    lang.t('!domain in !country - these are the most complete and accurate data', { '!domain': window.location.host, '!country': data.landingPageData.city.countryNameLocative }) :
                    lang.t('Your location: <a class="color-2" href="!link">!city</a>', { '!city': data.landingPageData.detectedCity.name, '!link': lang.l(data.landingPageData.detectedCity.link) }, { reactLink: true })
                  )
                }
              </div>
            </div>
            <div className="inline-block va-middle user-city-select-buttons">
              {
                (IS_SERVER || data?.landingPageData?.detectedCity?.id !== myCities[0]?.id) &&
                <>
                  {IS_SERVER && '{if (page.landingPageData.detectedCity.id != myCities[0].id) :}'}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a className="inline-block va-middle ta-center confirm text-10 color-9" onClick={confirmUserCityHandle} href="#">{lang.t('Yes')}</a>
                  {IS_SERVER && '{endif}'}
                </>
              }
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className="inline-block va-middle ta-center change text-10 color-10" onClick={changeUserCityHandle} href="#">{lang.t('Change')}</a>
            </div>
          </div>
        </div>
      </div>
      <div id="main-wrapper" className="relative">
        <main>
          <section id="section-weather" className="clear">
            <div id="sun-position-hourly">
              <SunPosition
                sunRise={IS_SERVER ? '{print page.landingPageData.city.date.sunrise}' : data.landingPageData.city.date.sunrise}
                sunSet={IS_SERVER ? '{print page.landingPageData.city.date.sunset}' : data.landingPageData.city.date.sunset}
                timezoneOffset={IS_SERVER ? '{print page.landingPageData.city.date.timezoneOffset}' : data.landingPageData.city.date.timezoneOffset}
              />
              <ScrollHidden tag="div" resizeProps={{ className: 'hourly-forecast-wrapper inline-block va-top' }} props={{ style: { overflow: 'hidden' } }} scrollbar={true} scrollWheel={false}>
                <table className="hourly-forecast">
                  <tbody>
                    <tr>
                      {(IS_SERVER ? [''] : data.landingPageData.city.weather.hourly).map((hourly, i) => (
                        <>
                          {IS_SERVER && '{for (let i = 0; i < page.landingPageData.city.weather.hourly.length; i++) :}'}
                            <td className="va-top ta-center relative">
                              <div className="relative">
                                <div className="time text-4 color-4">{IS_SERVER ? '{print page.landingPageData.city.weather.hourly[i].time}' : hourly.time}</div>
                                <img width="284" height="284" alt="Weather" src={IS_SERVER ? "{print Helper.getFileUrl('weather-icon', page.landingPageData.city.weather.hourly[i].icon + '.svg')}" : Helper.getFileUrl('weather-icon', `${hourly.icon}.svg`)}/>
                                <div className="temp text-19" style={{ color: IS_SERVER ? '{print weatherService.getTempColor(page.landingPageData.city.weather.hourly[i].temp)}' :  weatherService.getTempColor(hourly.temp) }}>
                                  {IS_SERVER ? '{print DimensionUnitsConvertor.temperature(page.landingPageData.city.weather.hourly[i].temp, dimensionUnits.temperature)}' : DimensionUnitsConvertor.temperature(hourly.temp, dimensionUnits.temperature)}
                                </div>
                                <div className="cloudiness text-18 color-3">{lang.t(IS_SERVER ? '{print page.landingPageData.city.weather.hourly[i].cloudiness}' : hourly.cloudiness)}</div>
                              </div>
                            </td>
                          {IS_SERVER && '{endif}'}
                        </>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </ScrollHidden>
            </div>
            <div className="weather-block-wrapper">
              <div id="weather-block-1" className="weather-block relative fs-0 va-middle inline-block">
                <Thermometer
                  temp={(() => IS_SERVER ? "DimensionUnitsConvertor.temperature(page.landingPageData.city.weather.temp, dimensionUnits.temperature).replace(/[^\\-\\d]/g, '')" : DimensionUnitsConvertor.temperature(data.landingPageData.city.weather.temp, dimensionUnits.temperature).replace(/[^\-\d]/g, ''))()}
                  minTemp={IS_SERVER ? "DimensionUnitsConvertor.temperature(-50, dimensionUnits.temperature).replace(/[^\\-\\d]/g, '')" : DimensionUnitsConvertor.temperature(-50, dimensionUnits.temperature).replace(/[^\-\d]/g, '')}
                  maxTemp={IS_SERVER ? "DimensionUnitsConvertor.temperature(50, dimensionUnits.temperature).replace(/[^\\-\\d]/g, '')" : DimensionUnitsConvertor.temperature(50, dimensionUnits.temperature).replace(/[^\-\d]/g, '')}
                  lang={lang}
                />
                <div className="inline-block va-middle img" style={{ backgroundImage: `url(${IS_SERVER ? "{print Helper.getFileUrl('images', 'weather_block_1.webp')}" : Helper.getFileUrl('images', 'weather_block_1.webp')})`}}/>
                <div className="inline-block va-middle weather-block-content">
                  <div className="title text-1 color-1">
                    {lang.t('Weather today in <span class="color-2">!city</span>', { '!city': IS_SERVER ? '{print page.landingPageData.city.cityNameLocative}' : data.landingPageData.city.cityNameLocative })}
                  </div>
                  <div className="text text-2 color-3" dangerouslySetInnerHTML={{ __html: IS_SERVER ? `{print DimensionUnitsConvertor.parse(${lang.localizationText('{print page.landingPageData.city.landingWeatherDescription}').replace(/^\{print |\}$/g, '')}, dimensionUnits, [translate, 'Home'], true)}` : DimensionUnitsConvertor.parse(lang.localizationText(data.landingPageData.city.landingWeatherDescription), dimensionUnits, lang, true) }}/>
                  <ul className="weather-params">
                    <li className="inline-block va-middle">
                      <img alt="Humidity" className="inline-block va-middle" src={IS_SERVER ? "{print Helper.getFileUrl('icons', 'humidity.svg')}" : Helper.getFileUrl('icons', 'humidity.svg')}/>
                      <div className="inline-block va-middle">
                        <span className="text-3 color-1">{lang.t('Humidity')}:</span>
                        <span className="text-4 color-4">{IS_SERVER ? '{print page.landingPageData.city.weather.humidity}' : data.landingPageData.city.weather.humidity}%</span>
                      </div>
                    </li>
                    <li className="inline-block va-middle">
                      <img className="inline-block va-middle" alt="Pressure" src={IS_SERVER ? "{print Helper.getFileUrl('icons', 'pressure.svg')}" : Helper.getFileUrl('icons', 'pressure.svg')}/>
                      <div className="inline-block va-middle">
                        <span className="text-3 color-1">{lang.t('Pressure')}:</span>
                        <span className="text-4 color-4">{IS_SERVER ? '{print page.landingPageData.city.weather.pressure}' : data.landingPageData.city.weather.pressure} <span>{lang.t('mmHg.')}</span></span>
                      </div>
                    </li>
                    <li className="inline-block va-middle">
                      <img className="inline-block va-middle" alt="Pressure" src={IS_SERVER ? "{print Helper.getFileUrl('icons', 'visibility.svg')}" : Helper.getFileUrl('icons', 'visibility.svg')}/>
                      <div className="inline-block va-middle">
                        <span className="text-3 color-1">{lang.t('Visibility')}:</span>
                        <span className="text-4 color-4">{IS_SERVER ? '{print page.landingPageData.city.weather.visibility}' : data.landingPageData.city.weather.visibility} {lang.t('m')}</span>
                      </div>
                    </li>
                    <li className="inline-block va-middle">
                      <img className={`inline-block va-middle wind-direction ${IS_SERVER ? '{print page.landingPageData.city.weather.windDirection}' : data.landingPageData.city.weather.windDirection}`} alt="Wind" src={IS_SERVER ? "{print Helper.getFileUrl('icons', 'wind-direction.svg')}" : Helper.getFileUrl('icons', 'wind-direction.svg')}/>
                      <div className="inline-block va-middle">
                        <span className="text-3 color-1">{lang.t(IS_SERVER ? '{print page.landingPageData.city.weather.windText}' : data.landingPageData.city.weather.windText)}:</span>
                        <span className="text-4 color-4">{IS_SERVER ? "{print DimensionUnitsConvertor.windSpeed((page.landingPageData.city.weather.wind || 0), dimensionUnits.windSpeed, [translate, 'Home'])}" : DimensionUnitsConvertor.windSpeed((data.landingPageData.city.weather.wind || 0), dimensionUnits.windSpeed, lang)}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <img alt="Advertising" className="va-middle inline-block" src={IS_SERVER ? "{print Helper.getFileUrl('images', 'ad_300x250.png')}" : Helper.getFileUrl('images', 'ad_300x250.png')}/>
            </div>
            <div className="weather-block-wrapper">
              <div id="weather-block-2" className="weather-block relative fs-0 va-middle inline-block">
              {
                (IS_SERVER || data.landingPageData.city.weather.waterGraph) &&
                <>
                  {IS_SERVER && '{if (page.landingPageData.city.weather.waterGraph) :}'}
                    <Thermometer
                      temp={IS_SERVER ? "DimensionUnitsConvertor.temperature(page.landingPageData.city.weather.waterGraph[0].temp, dimensionUnits.temperature).replace(/[^\\-\\d]/g, '')" : DimensionUnitsConvertor.temperature(data.landingPageData.city.weather.waterGraph[0].temp, dimensionUnits.temperature).replace(/[^\-\d]/g, '')}
                      minTemp={IS_SERVER ? "DimensionUnitsConvertor.temperature(-50, dimensionUnits.temperature).replace(/[^\\-\\d]/g, '')" : DimensionUnitsConvertor.temperature(-50, dimensionUnits.temperature).replace(/[^\-\d]/g, '')}
                      maxTemp={IS_SERVER ? "DimensionUnitsConvertor.temperature(50, dimensionUnits.temperature).replace(/[^\\-\\d]/g, '')" : DimensionUnitsConvertor.temperature(50, dimensionUnits.temperature).replace(/[^\-\d]/g, '')}
                      lang={lang}
                    />
                    <div className="inline-block va-bottom img" style={{ backgroundImage: `url(${IS_SERVER ? "{print Helper.getFileUrl('images', 'weather_block_2.webp')}" : Helper.getFileUrl('images', 'weather_block_2.webp')})`}}/>
                    <div className="inline-block va-bottom weather-block-content">
                      <div className="title text-1 color-1">{lang.t('Weather forecast for the water')}</div>
                      <Graph lang={lang} data={IS_SERVER ? 'page.landingPageData.city' : data.landingPageData.city}/>
                    </div>
                  {IS_SERVER && '{endif}'}
                </>
              }
              </div>
              <img alt="Advertising" className="va-middle inline-block" src={IS_SERVER ? "{print Helper.getFileUrl('images', 'ad_300x250.png')}" : Helper.getFileUrl('images', 'ad_300x250.png')}/>
            </div>
          </section>
          <section id="section-interactive-weather-map" className="fs-0">
            <img style={{ display: 'none' }} width="570" height="468" className="inline-block va-middle" alt="Map" src={IS_SERVER ? "{print Helper.getFileUrl('images', 'interactive_map.webp')}" : Helper.getFileUrl('images', 'interactive_map.webp')}/>
            <video src="/72298-541981714.mp4" style={{ borderRadius: '8px', objectFit: 'cover' }} width="570" height="468" loop muted autoplay="autoplay" className="inline-block va-middle"></video>
            <div className="inline-block va-middle">
              <div className="section-name text-12 color-12">{lang.t('Map')}</div>
              <div className="section-title text-7 color-1">{lang.localizationText(IS_SERVER ? '{print page.page.weather_map_title}' : data.page.weather_map_title)}</div>
              <div className="section-description text-2 color-4" dangerouslySetInnerHTML={{ __html: lang.localizationText(IS_SERVER ? '{print page.page.weather_map_description}' : data.page.weather_map_description) }}/>
              {
                (IS_SERVER || data.landingPageData.city.worldPart) &&
                <>
                  {IS_SERVER && '{if (page.landingPageData.city.worldPart) :}'}
                  <Link className="section-link text-13 color-9 inline-block" to={lang.l(IS_SERVER ? "{print '/' + page.landingPageData.city.worldPart + page.landingPageData.city.countryLink + page.landingPageData.city.cityLink.split('/').pop()}" : `/${data.landingPageData.city.worldPart}${data.landingPageData.city.countryLink}${data.landingPageData.city.cityLink.split('/').pop()}`)}>{lang.t('Go to the weather map')}</Link>
                  {IS_SERVER && '{endif}'}
                </>
              }
            </div>
          </section>
          <section id="section-advertising" className="relative">
            <div className="relative">
              <div className="inline-block va-middle">
                <div className="section-name text-12 color-12">{lang.t('Advertising')}</div>
                <div className="section-title text-7 color-1">{lang.localizationText(IS_SERVER ? '{print page.page.advertising_title}' : data.page.advertising_title)}</div>
                <div className="section-description text-2 color-4" dangerouslySetInnerHTML={{ __html: lang.localizationText(IS_SERVER ? '{print page.page.advertising_description}' : data.page.advertising_description) }}/>
                <Link className="section-link text-13 color-9 inline-block" to={lang.l('/advertising/')}>{lang.t('Cost and terms of accommodation')}</Link>
              </div>
              <ScrollHidden tag="div" props={{ style: { overflow: 'hidden', whiteSpace: 'nowrap' }, className: 'inline-block va-middle' }} scrollWheel={false}>
                <ul>
                  <li className="inline-block va-middle">
                    <div className="title">{lang.t('A large number of website users')}</div>
                    <img width="98" height="72" alt="Advertising" src={IS_SERVER ? "{print Helper.getFileUrl('images', 'advertising_1.svg')}" : Helper.getFileUrl('images', 'advertising_1.svg')}/>
                    <div className="user-quantity">631 574</div>
                    <div className="description">{lang.t('Users daily')}</div>
                  </li>
                  <li className="inline-block va-middle">
                    <div className="title">{lang.t('Monthly statistics')}</div>
                    <img width="284" height="136" alt="Advertising" src={IS_SERVER ? "{print Helper.getFileUrl('images', 'advertising_2.svg')}" : Helper.getFileUrl('images', 'advertising_2.svg')}/>
                    <div className="description">{lang.t('Google Analytics')}</div>
                  </li>
                  <li className="inline-block va-middle"/>
                </ul>
              </ScrollHidden>
              <img alt="Advertising" src={IS_SERVER ? "{print Helper.getFileUrl('images', 'advertising_bg.svg')}" : Helper.getFileUrl('images', 'advertising_bg.svg')} loading="lazy"/>
            </div>
          </section>
          <section id="section-widgets">
            <img width="597" height="476" alt="Widgets" className="inline-block va-middle" src={IS_SERVER ? "{print Helper.getFileUrl('images', 'widgets.svg')}" : Helper.getFileUrl('images', 'widgets.svg')} loading="lazy"/>
            <div className="inline-block va-middle">
              <div className="section-name text-12 color-12">{lang.t('Widgets')}</div>
              <div className="section-title text-7 color-1">{lang.localizationText(IS_SERVER ? '{print page.page.widgets_title}' : data.page.widgets_title)}</div>
              <div className="section-description text-2 color-4" dangerouslySetInnerHTML={{ __html: lang.localizationText(IS_SERVER ? '{print page.page.widgets_description}' : data.page.widgets_description) }}/>
              <Link className="section-link text-13 color-9 inline-block" to={lang.l('/widgets/')}>{lang.t('Create your weather widget')}</Link>
            </div>
          </section>
          <section id="section-advantages" className="fs-0 ta-center">
            <ScrollHidden tag="ul" props={{ style: { overflow: 'hidden', whiteSpace: 'nowrap' } }} scrollWheel={false}>
              <li className="inline-block va-middle relative text-6 color-1">{lang.t('Update every 30 minutes')}</li>
              <li className="inline-block va-middle relative text-6 color-1">{lang.t('Weather forecast for 10 days')}</li>
              <li className="inline-block va-middle relative text-6 color-1">{lang.t('Weather notifications')}</li>
              <li className="inline-block va-middle relative text-6 color-1">{lang.t('!cities Cities<br><span class="color-2">!countries countries of the world</span>', { '!cities': IS_SERVER ? "{print Helper.numberFormat(infoBlocks.citiesQuantity, 0, '', ' ')}" : Helper.numberFormat(infoBlocksData.citiesQuantity, 0, '', ' '), '!countries': IS_SERVER ? '{print infoBlocks.countriesQuantity}' : infoBlocksData.countriesQuantity })}</li>
              <li className="inline-block va-middle relative text-6 color-1">{lang.t('Weather forecast by the sea')}</li>
              <li className="inline-block va-middle relative text-6 color-1">{lang.t('Your advertisement on the site')}</li>
            </ScrollHidden>
          </section>
          <section id="section-popular-cities">
            <div className="section-title text-7 color-6">{lang.t('Popular cities of !country', { '!country': IS_SERVER ? '{print page.landingPageData.city.countryNameGenitive}' : data.landingPageData.city.countryNameGenitive })}</div>
            <CitiesList
              cities={IS_SERVER ? 'page.landingPageData.city.sidebarCities' : data.landingPageData.city.sidebarCities}
              countryLink={IS_SERVER ? 'page.landingPageData.city.countryLink' : data.landingPageData.city.countryLink}
            />
            <div className="separator"></div>
            <Link className="country-link-orange text-9 color-7 inline-block" to={lang.l(IS_SERVER ? "{print page.landingPageData.city.countryLink}" : data.landingPageData.city.countryLink)}>{lang.t('Weather forecast in !country', { '!country': IS_SERVER ? '{print page.landingPageData.city.countryNameLocative}' : data.landingPageData.city.countryNameLocative })}</Link>
          </section>
          {
            false &&
            <section id="section-about-us" className="relative">
              <div>
                <div className="section-name text-12 color-12">{lang.t('About us')}</div>
                <div className="section-title text-7 color-1">{lang.localizationText(IS_SERVER ? '{print page.page.about_us_title}' : data.page.about_us_title)}</div>
                <div className="section-description text-2 color-4" dangerouslySetInnerHTML={{ __html: lang.localizationText(IS_SERVER ? '{print page.page.about_us_description}' : data.page.about_us_description) }}/>
              </div>
            </section>
          }
        </main>
        <Footer/>
      </div>
    </ComponentStyle>
  );
}

function Graph({ data, lang }) {
  const { dimensionUnits } = useContext(FrontContext);

  const colWidth = !IS_SERVER && 100 / (data.weather.waterGraph.length - 2);

  const waterGraphStart = - 83.33;
  const waterGraphStep = 166.66;

  const waterGraphStartP = !IS_SERVER && -colWidth / 2;

  const waterGraphMinY = 74;
  const waterGraphMaxY = 134;
  const svgHeight = 165;

  let waterGraphMinTemp = Number.POSITIVE_INFINITY;
  let waterGraphMaxTemp = Number.NEGATIVE_INFINITY;

  const getX = (i) => lang.currentLanguage.direction == 'ltr' ?
    waterGraphStartP + colWidth * i :
    100 - waterGraphStartP - colWidth * i;

  let d = null;

  return (
    <ScrollHidden tag="div" props={{ style: { overflow: 'hidden' }, className: 'graph relative' }} scrollbar={true} scrollWheel={false}>
      <div className="relative inline-block">
        <ul className="relative">
          {
            IS_SERVER ?
            `
              {exec}
                dynamic_value = { colWidth: 100 / (${data}.weather.waterGraph.length - 2) };
                dynamic_value.waterGraphStart = ${waterGraphStart};
                dynamic_value.waterGraphStep = ${waterGraphStep};
  
                dynamic_value.waterGraphStartP = -dynamic_value.colWidth / 2;
  
                dynamic_value.waterGraphMinY = ${waterGraphMinY};
                dynamic_value.waterGraphMaxY = ${waterGraphMaxY};
                dynamic_value.svgHeight = ${svgHeight};
  
                dynamic_value.waterGraphMinTemp = Number.POSITIVE_INFINITY;
                dynamic_value.waterGraphMaxTemp = Number.NEGATIVE_INFINITY;

                dynamic_value.getX = (i) => staticData.language.direction == 'ltr' ?
                  dynamic_value.waterGraphStartP + dynamic_value.colWidth * i :
                  100 - dynamic_value.waterGraphStartP - dynamic_value.colWidth * i;
              {/exec}
              {for (let i = 0; i < ${data}.weather.waterGraph.length; i++) :}
                {exec}
                  if (${data}.weather.waterGraph[i].temp > dynamic_value.waterGraphMaxTemp) dynamic_value.waterGraphMaxTemp = ${data}.weather.waterGraph[i].temp;
                  if (${data}.weather.waterGraph[i].temp < dynamic_value.waterGraphMinTemp) dynamic_value.waterGraphMinTemp = ${data}.weather.waterGraph[i].temp;
                {/exec}
  
                {if (i > 0 && i < ${data}.weather.waterGraph.length - 1) :}
                  {exec}dynamic_value.date = Helper.dateFromFormat(${data}.weather.waterGraph[i].date, 'Y-m-d');{/exec}
                  {literal}
                    <li class="inline-block va-top ta-center relative text-5 color-1" style="width:{print dynamic_value.colWidth}%">
                      <div>{if (i == 1) :}${lang.t('Tomorrow')}{else :}{print dynamic_value.date.getDate()} <span>${lang.t({ string: '{print MONTHS[dynamic_value.date.getMonth()]}', variant: 1 })}</span>{endif}</div>
                      {if (i > 1) :}
                        <div></div>
                      {endif}
                    </li>
                  {/literal}
                {endif}
              {endfor}
              {exec}
                dynamic_value.d = ${data}.weather.waterGraph.map((w, i) => (!i ? "M " : "") + (i == 1 ? "L " : "") + dynamic_value.getX(i) + "," + (dynamic_value.waterGraphMaxTemp == dynamic_value.waterGraphMinTemp ? dynamic_value.svgHeight / 2 : dynamic_value.waterGraphMinY + ((dynamic_value.waterGraphMaxTemp - w.temp) / (dynamic_value.waterGraphMaxTemp - dynamic_value.waterGraphMinTemp) * (dynamic_value.waterGraphMaxY - dynamic_value.waterGraphMinY)))).join(" ");
              {/exec}
            ` :
            data.weather.waterGraph.map((w, i, a) => {
              if (w.temp > waterGraphMaxTemp) waterGraphMaxTemp = w.temp;
              if (w.temp < waterGraphMinTemp) waterGraphMinTemp = w.temp;
  
              if (!i || i === a.length - 1) return null;
  
              const date = Helper.dateFromFormat(w.date, 'Y-m-d');
  
              return (
                <li className="inline-block va-top ta-center relative text-5 color-1" style={{ width: `${colWidth}%` }}>
                  <div>{i === 1 ? lang.t('Tomorrow') : <>{date.getDate()} <span>{lang.t({ string: MONTHS[date.getMonth()], variant: 1 })}</span></>}</div>
                  {i && <div/>}
                </li>
              );
            })
          }
        </ul>
        <svg width="1000" height={svgHeight} viewBox={`0 0 100 ${svgHeight}`} preserveAspectRatio="none">
          <defs>
            <mask id="water-graph-mask">
              <rect x="0" y="0" width="100%" height="100%" fill="#000"></rect>
              <path
                d={
                  IS_SERVER ?
                  `{print dynamic_value.d + ' V ${svgHeight} H 0'}` :
                  (d = data.weather.waterGraph.map((w, i) => (!i ? "M " : "") + (i === 1 ? "L " : "") + getX(i) + "," + (waterGraphMaxTemp === waterGraphMinTemp ? svgHeight / 2 : waterGraphMinY + ((waterGraphMaxTemp - w.temp) / (waterGraphMaxTemp - waterGraphMinTemp) * (waterGraphMaxY - waterGraphMinY)))).join(" ")) + ` V ${svgHeight} H 0`
                }
                fill="#fff"
              />
            </mask>
          </defs>
          <rect x="0" y="0" width="100%" height="100%" mask="url(#water-graph-mask)" fill="#f7fafe"/>
          <path
            d={IS_SERVER ? `{print dynamic_value.d}` : d}
            stroke="#1581FA"
            fill="none"
            vector-effect="non-scaling-stroke"
          />
        </svg>
        {
          IS_SERVER ?
          <>
            {`{for (let i = 1; i < ${data}.weather.waterGraph.length - 1; i++) :}`}
              <div style={{ left: `{print dynamic_value.getX(i)}%`, bottom: `{print dynamic_value.waterGraphMaxTemp == dynamic_value.waterGraphMinTemp ? dynamic_value.svgHeight / 2 : dynamic_value.svgHeight - dynamic_value.waterGraphMinY - (dynamic_value.waterGraphMaxTemp - ${data}.weather.waterGraph[i].temp) / (dynamic_value.waterGraphMaxTemp - dynamic_value.waterGraphMinTemp) * (dynamic_value.waterGraphMaxY - dynamic_value.waterGraphMinY)}px` }} className="temp text-5 color-5">{`{print DimensionUnitsConvertor.temperature(${data}.weather.waterGraph[i].temp, dimensionUnits.temperature)}`}</div>
              <img style={{ left: '{print dynamic_value.getX(i)}%', bottom: `{print dynamic_value.waterGraphMaxTemp == dynamic_value.waterGraphMinTemp ? dynamic_value.svgHeight / 2 : dynamic_value.svgHeight - dynamic_value.waterGraphMinY - (dynamic_value.waterGraphMaxTemp - ${data}.weather.waterGraph[i].temp) / (dynamic_value.waterGraphMaxTemp - dynamic_value.waterGraphMinTemp) * (dynamic_value.waterGraphMaxY - dynamic_value.waterGraphMinY)}px` }} className="wave" src={Helper.getFileUrl('icons', 'graph-wave.svg')}/>
              <div style={{ left: '{print dynamic_value.getX(i)}%', bottom: `{print dynamic_value.waterGraphMaxTemp == dynamic_value.waterGraphMinTemp ? dynamic_value.svgHeight / 2 : dynamic_value.svgHeight - dynamic_value.waterGraphMinY - (dynamic_value.waterGraphMaxTemp - ${data}.weather.waterGraph[i].temp) / (dynamic_value.waterGraphMaxTemp - dynamic_value.waterGraphMinTemp) * (dynamic_value.waterGraphMaxY - dynamic_value.waterGraphMinY)}px` }} className="big-circle"></div>
              <div style={{ left: '{print dynamic_value.getX(i)}%', bottom: `{print dynamic_value.waterGraphMaxTemp == dynamic_value.waterGraphMinTemp ? dynamic_value.svgHeight / 2 : dynamic_value.svgHeight - dynamic_value.waterGraphMinY - (dynamic_value.waterGraphMaxTemp - ${data}.weather.waterGraph[i].temp) / (dynamic_value.waterGraphMaxTemp - dynamic_value.waterGraphMinTemp) * (dynamic_value.waterGraphMaxY - dynamic_value.waterGraphMinY)}px` }} className="middle-circle"></div>
              <div style={{ left: '{print dynamic_value.getX(i)}%', bottom: `{print dynamic_value.waterGraphMaxTemp == dynamic_value.waterGraphMinTemp ? dynamic_value.svgHeight / 2 : dynamic_value.svgHeight - dynamic_value.waterGraphMinY - (dynamic_value.waterGraphMaxTemp - ${data}.weather.waterGraph[i].temp) / (dynamic_value.waterGraphMaxTemp - dynamic_value.waterGraphMinTemp) * (dynamic_value.waterGraphMaxY - dynamic_value.waterGraphMinY)}px` }} className="small-circle"></div>
            {'{endfor}'}
          </> :
          data.weather.waterGraph.filter((e, i, a) => i && a.length - 1 > i).map((w, i) => (
            <>
              <div style={{ left: `${getX(i + 1)}%`, bottom: `${waterGraphMaxTemp === waterGraphMinTemp ? svgHeight / 2 : svgHeight - waterGraphMinY - (waterGraphMaxTemp - w.temp) / (waterGraphMaxTemp - waterGraphMinTemp) * (waterGraphMaxY - waterGraphMinY)}px` }} className="temp text-5 color-5">{DimensionUnitsConvertor.temperature(w.temp, dimensionUnits.temperature)}</div>
              <img style={{ left: `${getX(i + 1)}%`, bottom: `${waterGraphMaxTemp === waterGraphMinTemp ? svgHeight / 2 : svgHeight - waterGraphMinY - (waterGraphMaxTemp - w.temp) / (waterGraphMaxTemp - waterGraphMinTemp) * (waterGraphMaxY - waterGraphMinY)}px` }} className="wave" src={Helper.getFileUrl('icons', 'graph-wave.svg')}/>
              <div style={{ left: `${getX(i + 1)}%`, bottom: `${waterGraphMaxTemp === waterGraphMinTemp ? svgHeight / 2 : svgHeight - waterGraphMinY - (waterGraphMaxTemp - w.temp) / (waterGraphMaxTemp - waterGraphMinTemp) * (waterGraphMaxY - waterGraphMinY)}px` }} className="big-circle"></div>
              <div style={{ left: `${getX(i + 1)}%`, bottom: `${waterGraphMaxTemp === waterGraphMinTemp ? svgHeight / 2 : svgHeight - waterGraphMinY - (waterGraphMaxTemp - w.temp) / (waterGraphMaxTemp - waterGraphMinTemp) * (waterGraphMaxY - waterGraphMinY)}px` }} className="middle-circle"></div>
              <div style={{ left: `${getX(i + 1)}%`, bottom: `${waterGraphMaxTemp === waterGraphMinTemp ? svgHeight / 2 : svgHeight - waterGraphMinY - (waterGraphMaxTemp - w.temp) / (waterGraphMaxTemp - waterGraphMinTemp) * (waterGraphMaxY - waterGraphMinY)}px` }} className="small-circle"></div>
            </>
          ))
        }
      </div>
    </ScrollHidden>
  );
}

function HoverPopup({ map, lang, dimensionUnitsRef }) {
  const defaultHoverPopup = { style: null, className: '', content: <div className="map-hover-popup-waiting"></div> };
  const [hoverPopup, setHoverPopup] = useState(defaultHoverPopup);
  const hoverPopupRef = useRef(null);

  hoverPopupRef.current = hoverPopup;

  useEffect(() => {
    if (map) {
      const canvas = map.getCanvas();
      let timeout = null;
      let isMouseOverCanvas = false;
  
      canvas.addEventListener('mousemove', (ev) => {
        isMouseOverCanvas = true;
        setHoverPopup({ ...hoverPopupRef.current, style: { left: `${ev.clientX}px`, top: `${ev.clientY + (window.pageYOffset || document.documentElement.scrollTop)}px` } });
      });
  
      canvas.addEventListener('mouseleave', (ev) => {
        isMouseOverCanvas = false;
        setHoverPopup({ ...hoverPopupRef.current, style: null });
        if (timeout) clearTimeout(timeout);
      });
  
      map.on('mousemove', (ev) => {
        if (isMouseOverCanvas) {
          setHoverPopup({ ...hoverPopupRef.current, content: defaultHoverPopup.content, className: ' waiting' });
  
          if (timeout) clearTimeout(timeout);
  
          timeout = setTimeout(() => {
            axios
              .get(`${API_URI}/geocode/${ev.lngLat.lng},${ev.lngLat.lat}/${lang.currentLanguage._id}`, { withCredentials: true })
              .then(({ data }) => setHoverPopup({
                ...hoverPopupRef.current,
                className: '',
                content: (
                  <>
                    <img alt="Weather" className="inline-block va-middle" src={Helper.getFileUrl('weather-icon', data.weather.icon + '.svg')}/>
                    <div className="temp inline-block va-middle">{DimensionUnitsConvertor.temperature(data.weather.temp, dimensionUnitsRef.current.temperature)}</div>
                  </>
                )}
              ));
            timeout = null;
          }, 200);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return (
    hoverPopup.style ?
    <div style={hoverPopup.style} className={`map-hover-popup fs-0${hoverPopup.className}`}>
      {hoverPopup.content}
      <div className="red-circle"></div>
    </div> : null
  );
}

export default HomeLanding;